.login {
  background-color: #f8f8f8;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
}

.login__container {
  padding: 100px;
  text-align: center;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px;
}

.login__container > img {
  object-fit: contain;
  height: 100px;
  margin-bottom: 40px;
}

.login__buttons {
  display: flex;
  flex-direction: column;
}

.login__buttons > button {
  text-transform: inherit !important;
  margin-top: 20px;
}

.login__buttons > button:first-child {
  margin-top: 50px;
}

 /* add !important to override material-ui classes */
.login__button--green {
  background-color: #ffffff !important;
  color: #0a8d48 !important;
  border: 2px solid #0a8d48 !important;
}

.login__button--green:hover {
  background-color: #0a8d48 !important;
  color: #ffffff !important;
}

.login__button--gray {
  background-color: #ffffff !important;
  color: darkgray !important;
  border: 2px solid darkgray !important;
}

.login__button--gray:hover {
  background-color: darkgray !important;
  color: #ffffff !important;
}
