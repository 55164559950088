.sidebar {
  flex: 0.35;
  display: flex;
  flex-direction: column;
}

.sidebar__search {
  display: flex;
  align-items: center;
  background-color: #f6f6f6;
  height: 39px;
  padding: 10px;
}

.sidebar__searchContainer {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  border-radius: 20px;
}

.sidebar__searchContainer > .MuiSvgIcon-root {
  color: gray;
  padding: 10px;
}

.sidebar__searchContainer > input {
  border: none;
  margin-left: 10px;
  outline: none;
}

.sidebar__header {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  border-right: 1px solid lightgray;
}

.sidebar__headerRight {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 10vw;
}

.sidebar__headerRight > .MuiSvgIcon-root {
  margin-right: 2vw;
  font-size: 24px !important;
}

.sidebar__chats {
  flex: 1;
  background-color: #ffffff;
  overflow: auto;
}

.sidebar__menu {
  z-index: 1;
  left: 40px !important;
}
